/* ---------------------------------
contatti
--------------------------------- */

import {
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  UnorderedList,
  useDisclosure,
} from "@chakra-ui/react";
import { useLocation } from "@reach/router";
import React, { useEffect } from "react";
import { ContactForm } from "../../components/ContactForm";
import { Head } from "../../components/Head";
import { StyledLink } from "../../components/StyledLink";
import { TextParagraph } from "../../components/TextParagraph";
import BasePage from "../../templates/BasePage";

// contatti
export default () => {
  //modale apertura pagina contatti
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/contatti/") {
      onOpen();
    } else {
      onClose();
    }
  }, [location.pathname]);
  return (
    <BasePage title={"Contatti"}>
      <Head title="Contatti" />
      <ContactForm />
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody padding={5}>
            <TextParagraph>
              Gentile Cliente, <br />
              abbiamo rilevato che alcune richieste inviate dai clienti o utenti
              nel corso delle scorse settimane potrebbero non essere state
              tempestivamente riscontrate in quanto non acquisite regolarmente,
              per ragioni tecniche.
            </TextParagraph>
            <TextParagraph>
              Scusandoci per l'accaduto e volendo continuare ad offrire un
              servizio di qualità e la massima attenzione verso la nostra
              Clientela, nel caso non avesse ricevuto un riscontro completo alla
              sua richiesta, La invitiamo a:
            </TextParagraph>
            <UnorderedList styleType="disc" spacing={4} marginBottom={4}>
              <ListItem>
                consultare la sua Area Riservata, attraverso la quale potrà
                verificare le principali informazioni sulle sue pratiche e
                gestire in autonomia la maggior parte delle necessità (sito "
                <StyledLink href="https://www.hyundaicapitalitaly.com/">
                  www.hyundaicapitalitaly.com
                </StyledLink>
                " -
                <StyledLink href="https://www.hyundaicapitalitaly.com/area-riservata">
                  Area Riservata
                </StyledLink>
                );
              </ListItem>
              <ListItem>
                ad accedere tramite il link al sito di Hyundai Capital Bank, dal
                quale potrà inoltrare direttamente le sue domande per iscritto,
                compilando il form dedicato “
                <StyledLink href="https://www.hyundaicapitalitaly.com/chiedi-esperto/">
                  Chiedi a un esperto
                </StyledLink>
                ” e ottenere una gestione prioritaria della sue richieste.
              </ListItem>
            </UnorderedList>
            <TextParagraph>
              La ringraziamo della sua collaborazione e restiamo a Sua completa
              disposizione.
            </TextParagraph>
          </ModalBody>
        </ModalContent>
      </Modal>
    </BasePage>
  );
};
